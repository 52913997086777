import { render, staticRenderFns } from "./DogovorOprostovoljskemDelu.vue?vue&type=template&id=90e4ef48&"
import script from "./DogovorOprostovoljskemDelu.vue?vue&type=script&lang=js&"
export * from "./DogovorOprostovoljskemDelu.vue?vue&type=script&lang=js&"
import style0 from "./DogovorOprostovoljskemDelu.vue?vue&type=style&index=0&id=90e4ef48&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports